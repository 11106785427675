<template>
    <div v-if="loaded" class="dashboard-card" :class="{ 'dashboard-card--disabled': !total }">
        <header class="dashboard-card__header">
            <CIcon :name="chartIcon" class="dashboard-card__icon dashboard-card__icon--pencil"/>
            <h4 class="dashboard-card__heading">{{ title }}</h4>
            <CIcon name="cilArrowCircleRight" size="lg" class="dashboard-card__icon dashboard-card__icon--arrow"/>
        </header>

        <div class="dashboard-card__content">
            <div class="dashboard-card__wrapper">
                <CChart v-if="total" class="dashboard-card__chart" type="doughnut" :plugins="plugins" :options="options"
                        :data="data"/>
                <div v-else class="dashboard-card__chart dashboard-card__chart--disabled"></div>

                <div class="dashboard-card__inner">
                    <img src="/images/dashboard-icons/inner-arrow.svg" class="dashboard-card__arrow"/>
                    <div class="dashboard-card__info">
                        <h2 class="dashboard-card__total">{{ total }}</h2>
                        <div class="dashboard-card__overdue">
                            <CIcon name="cilExclamationCircle"
                                   class="dashboard-card__icon dashboard-card__icon--exclamation"/>
                            <span class="dashboard-card__text">{{ overdue }} Overdue</span>
                        </div>
                    </div>
                </div>

                <div v-if="tooltipData" :style="{ top: tooltipData?.top, left: tooltipData?.left }"
                     class="dashboard-card__tooltip">
                    <h4 class="dashboard-card__tooltip-heading">
                        {{ tooltipData.title }}
                    </h4>

                    <ul class="dashboard-card__tooltip-list">
                        <li class="dashboard-card__tooltip-item">{{ tooltipData.open }} Open</li>
                        <li class="dashboard-card__tooltip-item"
                            :class="{ 'dashboard-card__tooltip-item--overdue': tooltipData.overdue }">
                            {{ tooltipData.overdue }} Overdue
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="disclaimer">
            <div class="text-information small">
                <span :class="{'blue': isGeneralPlan}"></span>
                Buyer
            </div>
            <div class="text-information small mt-2">
                <span :class="{'blue': isSubPlan}"></span>
                Seller
            </div>
        </div>
    </div>
</template>
<script>
import {CChart} from '@coreui/vue-chartjs'
import ChartJSPluginDatalabels from 'chartjs-plugin-datalabels'
import {mapGetters} from "vuex";

export default {
    name: 'DashboardCard',
    components: {CChart},
    props: {
        title: {
            type: String,
            default: '',
        },
        total: {
            type: Number,
            default: 0,
        },
        overdue: {
            type: Number,
            default: 0,
        },
        backgroundColor: {
            type: Array,
            default: () => [],
        },
        chartData: {
            type: Array,
            default: () => [],
        },
        additionalData: {
            type: Array,
            default: () => [],
        },
        icons: {
            type: Array,
            default: () => []
        },
        chartIcon: {
            type: String,
            default: 'cilPen'
        }
    },
    data(modelCtx) {
        return {
            loaded: false,
            tooltipData: null,
            plugins: [ChartJSPluginDatalabels],
            options: {
                plugins: {
                    legend: {
                        display: false,
                    },
                    datalabels: {
                        formatter: function (value, context) {
                            return context.dataset.icons[context.dataIndex]
                        },
                        color: '#fff',
                        font: {
                            family: '"Font Awesome 5 Free"',
                            size: 10,
                            weight: 700,
                        },
                    },
                    tooltip: {
                        enabled: false,
                        external: function (context) {
                            const tooltipModel = context.tooltip

                            if (tooltipModel.opacity === 0) {
                                modelCtx.setTooltipData(null)
                            } else {
                                const data = tooltipModel?.dataPoints[0]
                                const dataIndex = data?.dataIndex
                                const additionalData = data?.dataset?.additionalData[dataIndex]

                                if (!additionalData) return

                                const {title, total, open, overdue} = additionalData
                                const left = tooltipModel.caretX + 'px'
                                const top = tooltipModel.caretY + 'px'

                                modelCtx.setTooltipData({
                                    title: `${total} ${title}`,
                                    open: open,
                                    overdue: overdue,
                                    top,
                                    left,
                                })
                            }
                        },
                    },
                },
            },
            data: {
                datasets: [
                    {
                        backgroundColor: modelCtx.backgroundColor,
                        data: modelCtx.chartData,
                        additionalData: modelCtx.additionalData,
                        icons: this.icons,
                        borderWidth: 0,
                        cutout: 65,
                    },
                ],
            },
        }
    },
    computed: {
        ...mapGetters(['isGeneralPlan', 'isSubPlan'])
    },
    methods: {
        setTooltipData(data) {
            this.tooltipData = data
        },
    },
    created() {
        document.fonts.ready.then(() => {
            this.loaded = true
        })
    }
}
</script>

<style lang="scss">
.dashboard-card {
    $parent: &;

    border-radius: 8px;
    background-color: #ffffff;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.2);
    padding: 8px 16px 24px;
    position: relative;

    & i {
        display: inline-block;
        font-size: inherit;
        line-height: 1;
        transform: scale(5);
    }

    &--disabled {
        #{$parent} {
            &__icon {
                &--pencil {
                    color: #c3cdd5;

                    & path {
                        fill: #c3cdd5;
                    }
                }

                &--arrow {
                    color: #c3cdd5;
                }

                &--exclamation {
                    color: #c3cdd5;
                }
            }

            &__heading,
            &__total,
            &__text {
                color: #c3cdd5;
            }

            &__arrow {
                opacity: 0.5;
            }

            &__header {
                border-bottom: 1px solid #c3cdd5;
            }
        }
    }

    &__header {
        display: flex;
        align-items: center;
        padding-bottom: 8px;
        border-bottom: 1px solid #dee4e8;
    }

    &__icon {
        &--pencil {
            color: #677a89;

            & path {
                fill: #677a89;
            }
        }

        &--arrow {
            color: #0081c2;
        }

        &--exclamation {
            color: #ff1f26;
        }
    }

    &__heading {
        margin-bottom: 0;
        margin-left: 4px;
        margin-right: auto;
        color: #1c262f;
        font-family: 'Montserrat';
        font-size: 16px;
        font-weight: 600;
        line-height: calc(28 / 16);
    }

    &__content {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 16px;
    }

    &__wrapper {
        position: relative;
        width: 184px;
        height: 184px;
    }

    &__chart {
        width: 100%;
        height: 100%;

        &--disabled {
            border: 24px solid #dee4e8;
            border-radius: 100%;
        }
    }

    &__inner {
        position: absolute;
        top: 30px;
        left: 30px;
        right: 30px;
        bottom: 30px;
        border-radius: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &__arrow {
        position: absolute;
        top: 6px;
        right: 7px;
        width: 54px;
        height: 59px;
        object-fit: contain;
        pointer-events: none;
        user-select: none;
    }

    &__info {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    &__total {
        margin-bottom: 0;
        font-size: 44px;
        font-family: 'Montserrat';
        color: #1c262f;
        font-weight: 800;
        line-height: calc(32 / 44);
    }

    &__overdue {
        display: flex;
        align-items: center;
        margin-top: 6px;
    }

    &__text {
        font-size: 12px;
        font-weight: 700;
        line-height: calc(20 / 12);
        color: #677a89;
        margin-left: 2px;
    }

    &__tooltip {
        white-space: nowrap;
        position: absolute;
        background-color: #fff;
        display: flex;
        flex-direction: column;
        border-radius: 4px;
        padding: 8px 12px;
        box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.2);
        z-index: 10;
        pointer-events: none;
    }

    &__tooltip-heading {
        text-transform: capitalize;
        margin-bottom: 0;
        font-size: 12px;
        font-weight: 700;
        line-height: calc(20 / 12);
        padding-bottom: 4px;
        border-bottom: 1px solid #c3cdd5;
    }

    &__tooltip-list {
        list-style: none;
        padding: 0;
        margin: 0;
    }

    &__tooltip-item {
        color: #677a89;
        font-size: 12px;
        font-weight: 700;
        line-height: calc(20 / 12);

        &--overdue {
            color: #ff1f26;
        }
    }

    .disclaimer {
        line-height: 1;
        position: absolute;
        bottom: 5px;
        left: 5px;

        span {
            width: 10px;
            height: 10px;
            background: #c3cdd5;
            display: inline-block;
            vertical-align: top;
            border-radius: 50%;
            margin: 2.5px 4px 0;

            &.blue {
                background: #1195d1;
            }
        }
    }
}
</style>
