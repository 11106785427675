<template>
    <div class="timeline-card">
        <div class="timeline-card__heading">
            <strong>{{ card.type_name }} </strong>
            <div class="d-flex align-items-center">
                <span>{{ card.status }}</span>
                <i>●</i>
                <span>{{ updatedDate }}</span>
            </div>
        </div>
        <div class="timeline-card__body">
            <router-link class="timeline-card__link" :to="card.link">{{ card.name }}</router-link>
            <p class="timeline-card__project">{{ card.project_number }} <span>- {{ card.project_name }}</span></p>

            <div class="timeline-card__collapse">
                <p class="timeline-card__company">{{ card.external_company_name }}</p>
                <i v-if="card.last_activity && card.last_activity.length > 1" class="fas"
                   :class="[list ? 'fa-chevron-up' : 'fa-chevron-down']" @click="list = !list"/>
            </div>

            <div class="timeline-card__list" v-if="list">
                <div class="timeline-card__user" v-for="user in card.last_activity">
                    <strong>{{ user.user_name }}</strong>
                    <div class="timeline-card__user-action">
                        <span>{{ user.status }} - {{ getActivityDate(user.date) }}</span>
                    </div>
                </div>
            </div>

            <div class="timeline-card__user" v-if="!list && card.last_activity.length">
                <strong>{{ card.last_activity[0].user_name }}</strong>
                <div class="timeline-card__user-action">
                    <span>{{ card.last_activity[0].status }} - {{ getActivityDate(card.last_activity[0].date) }}</span>
                </div>
            </div>
        </div>

        <div v-if="card.notification"
             :class="['timeline-card__notification', `timeline-card__notification--${ card.notification.icon }` ]">
            <div>
                <img v-if="card.notification.icon === 'success'" src="@/assets/images/success-badge.svg" alt="">
                <CIcon icon="cilWarningFilled" v-else/>
            </div>
            <span>{{ card.notification.message }}</span>
        </div>
    </div>
</template>

<script>
import moment from "moment";

export default {
    name: "TimelineCard",
    props: ['card'],
    data() {
        return {
            list: false
        }
    },
    methods: {
        getActivityDate(date) {
            const format = moment.utc(date).local().isSame(moment().local(), 'day') ? 'hh:mm A' : 'MM/DD'

            return moment.utc(date).local().format(format)
        }
    },
    computed: {
        updatedDate() {
            return moment.utc(this.card.updated_at).local().format('MM/DD')
        },
    }
}
</script>

<style lang="scss">
.timeline-card {
    background-color: #fff;
    border-bottom: 4px solid #DEE4E8;

    &__heading {
        padding: 2px 10px;
        justify-content: space-between;
        align-items: center;
        color: #1C262F;
        border-bottom: 1px solid #C3CDD5;

        & strong {
            font-size: 12px;
            font-weight: 700;
            font-family: "Segoe UI", sans-serif;
            text-transform: uppercase;
        }

        .d-flex {
            font-size: 11px;

            span:first-child {
                font-weight: bolder;
                flex-grow: 1;
            }

            i, span:last-child {
                margin-left: 5px;
            }
        }
    }

    &__body {
        padding: 10px;

        & a {
            color: #0068AD;
            font-size: 16px;
            font-weight: 500;
            margin-bottom: 10px;
            display: block;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
        }
    }

    &__project {
        font-size: 12px;
        font-weight: 600;
        color: #1C262F;
        margin-bottom: 0;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;

        & span {
            color: #677A89;
        }
    }

    &__company {
        font-size: 12px;
        font-weight: 600;
        color: #677A89;
        margin-bottom: 0;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
    }

    &__collapse {
        display: flex;
        justify-content: space-between;
        align-items: center;

        & i {
            font-size: 13px;
            margin-left: 5px;
            cursor: pointer;
        }
    }

    &__list {
        padding-top: 10px;

        & .timeline-card__user {
            border-bottom: 1px solid #C3CDD5;
        }
    }

    &__user {
        font-size: 12px;
        padding: 10px 0 5px 0;

        &-action {
            padding-left: 10px;
        }

        & strong {
            flex-shrink: 0;
        }
    }

    &__notification {
        height: 36px;
        display: flex;
        align-items: center;
        padding: 10px;

        &--danger {
            background: rgba(255, 153, 163, 0.85);
        }

        &--warning {
            background: #FFBF59;
        }

        &--success {
            background: #9FE9DA;
        }

        & span {
            font-size: 12px;
            font-weight: 400;
            color: #000000;
            margin-left: 5px;
            display: block;
        }

        & svg path {
            fill: black;
        }
    }
}
</style>
